import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserUpdateDialogService {
  currentUpdateStageProgress = new BehaviorSubject(null);

  setStage(stage: string): void {
    this.currentUpdateStageProgress.next({ stage });
  }
}
