import { UserUpdateDialogService } from './../../shared/services/user-update-dialog.service';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface UserUpdateStageData {
  stage: string;
}

export enum UpdateStage {
  updating_user = 'UPDATING_USER',
  updating_subs = 'UPDATING_SUBSCRIPTIONS',
  updating_roles = 'UPDATING_ROLES',
  close = 'CLOSE',
}

@Component({
  selector: 'app-user-update-dialog',
  templateUrl: './user-update-dialog.component.html',
  styleUrls: ['./user-update-dialog.component.scss'],
})
export class UserUpdateDialogComponent {
  dialogState: string = UpdateStage.updating_user;
  stage = UpdateStage;

  constructor(
    private userUpdateDialogService: UserUpdateDialogService,
    public dialogRef: MatDialogRef<UserUpdateDialogComponent>,
  ) {
    this.userUpdateDialogService.setStage(UpdateStage.updating_user);
    this.userUpdateDialogService.currentUpdateStageProgress.subscribe((data: UserUpdateStageData) => {
      if (data) {
        if (data.stage == 'CLOSE') {
          this.closeDialog();
        }
        this.dialogState = data.stage;
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
