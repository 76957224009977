import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from '../user-form/user-form.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared/services/user.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { CountriesService } from '../shared/services/countries.service';
import { Address, Phone, UserPatch, UserPost, UserStatus } from '../shared/model/user.model';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { LocalesService } from '../shared/services/locales.service';
import { PermissionService } from '../shared/services/permissions.service';
import { UserUpdateDialogComponent } from '../dialogs/update_roles/user-update-dialog.component';
import { BusinessRolesService } from '../shared/services/business-roles.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  userForm: UntypedFormGroup = new UntypedFormGroup({});
  SUCCESS = 'Changes saved successfully!';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your user';
  ROLES_FAILED = 'Oops, There was a problem applying the roles';
  USED_ECRM_ID = 'Oops, ECRM ID already in use';
  USED_ECRM_CONTACT_ID = 'Oops, ECRM Contact ID already in use';
  REQUIRED = 'required';
  appPrefix = 'usr';
  appData: any = {};
  org = '';
  selectedId = '';
  isSubmitting = false;
  userStatus$: Observable<UserStatus>;
  subscriptions: Subscription[] = [];
  canSubmitRoles = false;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  locales: Locale[];
  userLocale = 'en_US';

  readonly mode = 'edit';

  private userComponentView: UserFormComponent;

  @ViewChild(UserFormComponent, { static: true })
  set userComponent(userDetailsComponent: UserFormComponent) {
    this.userComponentView = userDetailsComponent;
  }

  get userComponent() {
    return this.userComponentView;
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private userService: UserService,
    private contextSelectorService: ContextSelectorService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private countriesService: CountriesService,
    private formValidatorService: FormValidatorService,
    private form: NgForm,
    private localesService: LocalesService,
    private permissionService: PermissionService,
    private businessRolesService: BusinessRolesService,
  ) {
    const translationSub = this.translateService
      .get('user.notification.updated_successfully')
      .subscribe((result: string) => {
        this.SUCCESS = result;
        this.BAD_REQUEST = this.translateService.instant('user.notification.bad_request');
        this.NOT_CREATED = this.translateService.instant('user.notification.not_updated');
        this.REQUIRED = this.translateService.instant('user.validation.required');
        this.ROLES_FAILED = this.translateService.instant('user.notification.error_applying_roles');
        this.USED_ECRM_ID = this.translateService.instant('user.notification.ecrm_id_already_in_use');
        this.USED_ECRM_CONTACT_ID = this.translateService.instant('user.notification.ecrm_contact_id_already_in_use');
      });

    const currentContextSub = this.contextSelectorService.currentContext$.subscribe((orgs: Context[]) => {
      this.org = orgs[0].id;
    });

    this.userStatus$ = this.userService.user$.pipe(map((user) => user.status));

    this.subscriptions.push(...[translationSub, currentContextSub]);
  }

  ngOnInit() {
    this.userForm = new UntypedFormGroup({});

    const routeSub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.selectedId = params.id;
        this.userService.selectedUserId$.next(this.selectedId);
      }
    });

    this.localesService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        this.multiLocaleConfig.defaultLocale =
          this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      }
    });

    const permissionSub = this.permissionService.permissions$.subscribe((permissions) => {
      if (permissions) {
        if (this.permissionService.canSubmitRoles()) {
          this.canSubmitRoles = true;
        }
      }
    });

    this.subscriptions.push(...[routeSub, permissionSub]);
  }

  async handleSubmit() {
    this.userService.savingUser$.next(true);

    if (!this.userForm.valid) {
      this.messageService.setError(this.REQUIRED);
      this.userService.savingUser$.next(false);
      return;
    }

    this.isSubmitting = true;

    this.dialog.open(UserUpdateDialogComponent, {
      width: '400px',
      disableClose: true,
    });

    try {
      let addresses: Address[] = this.userComponent.user.addresses.filter(this.userService.filterAddress);

      if (addresses && addresses.length === 0) {
        addresses = undefined;
      } else {
        addresses.forEach((address) => {
          if (address.country) {
            address.country_name = this.countriesService.getCountryNameFromId(address.country);
          }
        });
      }

      let phones: Phone[] = this.userComponent.user.phones.filter(this.userService.filterPhones);

      phones = phones.map((phone: any) => {
        let { extension } = phone;

        if (extension === '') {
          extension = undefined;
        }

        return {
          primary: phone.primary,
          phone_type: phone.phone_type || 'WORK',
          phone_number: phone.phone_number.replace(/[^0-9]/gi, ''),
          extension,
          country_code: phone.country_code.replace(/[^0-9]/gi, ''),
        };
      });

      if (phones && phones.length === 0) {
        phones = undefined;
      }

      const updatedUser: UserPost = {
        sync_to_ecrm: this.userComponent.user.sync_to_ecrm,
        username: this.userComponent.user.username.trim(),
        first_names: this.userComponent.user.first_names,
        last_names: this.userComponent.user.last_names,
        email: this.userComponent.user.email.trim(),
        phones,
        addresses,
        title: this.userComponent.user?.title?.trim() ?? '',
        user_type: this.userComponent.user.userType,
        locale: this.userComponent.user.locale,
        timezone: this.userComponent.user.timezone,
        brand: this.userComponent.user.brand || undefined,
        unit_system: this.userComponent.user.unit_system,
        home_application: this.userComponent.user.home_application,
        organization: this.userComponent.user.organization_id,
        ecrm_id: this.userComponent.user.ecrm_id,
        contact_id: this.userComponent.user.contact_id,
        primary_contact: this.userComponent.user.primary_contact,
        twentyfour_seven_contact: this.userComponent.user.twentyfour_seven_contact,
      };

      const updatedUserECRM: UserPatch = {
        federated_applications: [
          {
            application_name: 'ECRM_SSO',
            username: this.userComponent.user.ecrm_id,
          },
        ],
      };

      await this.userService.updateUser(this.selectedId, updatedUser);

      if (this.userComponent.user.ecrm_id) {
        await this.userService.updateUserECRM(this.selectedId, updatedUserECRM);
      }

      try {
        if (this.canSubmitRoles) {
          const results = await this.userComponentView.submitRoles();
          console.log('No Errors:', results);
        }
      } catch (error) {
        this.dialog.closeAll();
        console.log('Failed submitting roles', error);
        this.messageService.setError(this.ROLES_FAILED);
        this.userService.savingUser$.next(false);
        return;
      }

      this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix, callbackValue: this.selectedId });
      this.dialog.closeAll();
      this.messageService.setSuccess(this.SUCCESS);
      setTimeout(() => {
        this.userService.refetchUsers();
        this.router.navigate([`details/${this.selectedId}/view`], {});
      }, 0);
    } catch (e) {
      console.error(e);
      this.dialog.closeAll();
      const errorMessage = e.error.message;
      this.userService.savingUser$.next(false);
      if (errorMessage === 'ERR_BAD_REQUEST') {
        this.messageService.setError(this.BAD_REQUEST);
      } else if (errorMessage === 'Unprocessable Entity' && e.error.detail.includes('UNIQUE_ALT_USERNAME')) {
        this.messageService.setError(this.USED_ECRM_ID);
      } else if (errorMessage === 'Bad Request' && e.error.detail.includes('ECRM_ID_USED')) {
        this.messageService.setError(this.USED_ECRM_ID);
      } else if (errorMessage === 'Bad Request' && e.error.detail.includes('ECRM_CONTACT_ID_USED')) {
        this.messageService.setError(this.USED_ECRM_CONTACT_ID);
      } else {
        this.messageService.setError(this.NOT_CREATED);
      }
    } finally {
      this.isSubmitting = false;
      this.userService.savingUser$.next(false);
    }
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix });
    setTimeout(() => {
      this.userComponent.ActiveLocationRoles.errorRoles([]);
      this.userComponent.ActiveLocationRoles.resetRoles();
      
      this.businessRolesService.clearNewSpaces$.next(true);
      this.businessRolesService.newSpacesWithRoles$.next([]);
    }, 10);
    if (this.selectedId) {
      this.router.navigate([`details/${this.selectedId}/view`], {});
    } else {
      this.router.navigate(['/']);
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.userComponent.user && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.userComponent.user.first_names);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    } else if (this.multiLocaleConfig.supportedLocales.length === 1) {
      this.formValidatorService.resetFieldDefaultLocaleField(
        this.form.form['controls']['app-edit']['controls'].defaultLocale,
      );
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
