import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ContactSubscriptionService } from '../services/contact-subscriptions.service';
import { SpaceRole } from 'src/app/shared/model/business-role.model';
import { BusinessRolesService } from '../../shared/services/business-roles.service';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss', '../shared.styles.scss'],
})
export class SubscriptionTableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['site', 'text', 'voice', 'email'];
  @Input() mode: string;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  loading = false;
  loadingLocationsAndRoles: boolean;
  spacesWithRoles: SpaceRole[] = [];
  destroySubject$: Subject<void> = new Subject();
  searchText = '';
  userId: string;

  EDIT = 'edit';
  subscriptions = [];
  subs: Subscription[] = [];

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  constructor(
    private contactSubscriptionService: ContactSubscriptionService,
    private businessRolesService: BusinessRolesService,
    private route: ActivatedRoute,
  ) {
    const spacesAndRolesSub = this.businessRolesService.spacesWithRoles$.subscribe((spacesWithRoles) => {
      this.spacesWithRoles = spacesWithRoles;
      this.loadingLocationsAndRoles = false;
      this.getDataSource();
    });
    const loading = this.contactSubscriptionService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
    const subscriptions = this.contactSubscriptionService.contactSubscriptions$.subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
      this.getDataSource();
    });
    this.subs.push(...[subscriptions, spacesAndRolesSub, loading]);
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.spacesWithRoles = [];
        this.userId = params.id;
        this.subscriptions = [];
        this.dataSource = new MatTableDataSource();
        this.contactSubscriptionService.getContactSubscriptions(this.userId);
      }
    });
    this.subs.push(routeSub);
  }

  newElement(list, sub, role) {
    const subObj = {
      subscriptionId: sub.subscriptionId,
      entityId: sub.entityId,
      name: role.name,
      sms: false,
      voice: false,
      email: false,
    };
    switch (sub.communicationName) {
      case 'NOTIFY_EMAIL':
        subObj.email = true;
        break;
      case 'NOTIFY_PHONE':
        subObj.voice = true;
        break;
      case 'NOTIFY_SMS':
        subObj.sms = true;
        break;
    }
    list.push(subObj);
    return list;
  }

  updateElement(list, sub, pos) {
    switch (sub.communicationName) {
      case 'NOTIFY_EMAIL':
        list[pos].email = true;
        break;
      case 'NOTIFY_PHONE':
        list[pos].voice = true;
        break;
      case 'NOTIFY_SMS':
        list[pos].sms = true;
        break;
    }
    return list;
  }

  getDataSource() {
    if (this.spacesWithRoles.length > 0 && this.subscriptions.length > 0) {
      let list = [];
      this.subscriptions.forEach((sub) => {
        this.spacesWithRoles.forEach((role) => {
          if (role.id === sub.entityId) {
            const pos = list.findIndex((list) => {
              return list.entityId === role.id;
            });
            if (pos !== -1) {
              list = this.updateElement(list, sub, pos);
            } else {
              list = this.newElement(list, sub, role);
            }
          }
        });
      });
      list.sort((a: any, b: any) => a.name?.localeCompare(b.name));
      this.dataSource = new MatTableDataSource(list);
    }
  }

  applyFilter(search: string) {
    this.dataSource.filter = search.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
