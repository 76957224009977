import { Injectable } from '@angular/core';
import { Space } from 'ngx-global-nav';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { AtlasPagesService } from 'atlas-pages-loader';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';

const SPACES_HIERARCHY = ['organization', 'spacegroup', 'site', 'meter'];
const CONTEXT_HIERARCHY = ['organization'];

@Injectable()
export class SpaceService {
  hierarchyService: AtlasPagesService;

  hierarchy$: BehaviorSubject<Space[]>;
  readonly spaces$ = new BehaviorSubject<Space[]>([]);
  readonly sites$ = new BehaviorSubject<Space[]>([]);
  readonly contextList$ = new BehaviorSubject<Space[]>([]);
  loadingSpaces = false;

  constructor(private apiService: ApiService, private cookieService: CookieService, private httpClient: HttpClient) {
    this.sites$ = new BehaviorSubject<Space[]>([]);

    this.hierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async getSites(orgId: string) {
    try {
      const sites = await this.apiService.get(`organizations/${orgId}/sites`);
      this.sites$.next(convertKeys.toCamel(sites));
    } catch (err) {
      console.log('Could not load sites.', err);
    }
  }

  async getHierarchy() {
    try {
      const hierarchy = await this.apiService.get(`hierarchy`);
      // const filteredHierarchy = hierarchy.filter(org => {
      //   return org.display_label.toLowerCase().includes('demo');
      // });
      this.hierarchy$.next(convertKeys.toCamel(hierarchy));
    } catch (err) {
      console.log('Could not load hierarchy.', err);
    }
  }

  async getSpecificHierarchies(hierachy: string[]) {
    return await this.hierarchyService.getSpecificHierarchies(hierachy);
  }

  async getOrgHierarchy(orgId?: string) {
    this.loadingSpaces = true;
    let allSpaces;
    if (orgId) {
      allSpaces = await this.hierarchyService.getSpecificHierarchies(SPACES_HIERARCHY, {
        id: orgId,
        dr_type: 'ORGANIZATION',
      });
    } else {
      allSpaces = await this.hierarchyService.getSpecificHierarchies(SPACES_HIERARCHY);
    }

    this.spaces$.next(convertKeys.toCamel(allSpaces));
    this.loadingSpaces = false;
  }

  async getContextList() {
    const spaces = await this.hierarchyService.getSpecificHierarchies(CONTEXT_HIERARCHY);
    this.contextList$.next(spaces as Space[]);
  }
}
