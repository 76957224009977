<h4 class="card-title" data-automation="subscriptions_title">
    {{ 'user.subscriptions' | translate }}
</h4>
<div class="assign-sub"></div>
<div class="spinner-container" *ngIf="loading || loadingLocationsAndRoles; else finishedLoading">
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
</div>
<ng-template #finishedLoading>
    <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 sub-table">
            <!-- Site Search Column -->
            <ng-container matColumnDef="site">
                <th mat-header-cell class="search-th" *matHeaderCellDef>
                    <mat-form-field class="search-input" appearance="outline" floatLabel="always">
                        <input
                            matInput
                            type="text"
                            class="search-text"
                            data-automation="search"
                            (input)="applyFilter($event.target.value)"
                            placeholder="{{ 'user.table.search_site' | translate }}"
                        />
                    </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- Text Column -->
            <ng-container matColumnDef="text">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                    {{ 'user.table.sms' | translate }}
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox class="checkbox" [checked]="element.sms" [disabled]="true"> </mat-checkbox>
                </td>
            </ng-container>

            <!-- Voice Column -->
            <ng-container matColumnDef="voice">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                    {{ 'user.table.voice' | translate }}
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox class="checkbox" [checked]="element.voice" [disabled]="true"> </mat-checkbox>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                    {{ 'user.table.email' | translate }}
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox class="checkbox" [checked]="element.email" [disabled]="true"> </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="header-row-first-group">
                <th mat-header-cell *matHeaderCellDef class="title centered method th-site" [attr.colspan]="1">
                    {{ 'user.site' | translate }}
                </th>
            </ng-container>

            <ng-container matColumnDef="header-row-second-group">
                <th mat-header-cell *matHeaderCellDef class="title centered method dispatch" [attr.colspan]="3">
                    {{ 'user.table.dispatch' | translate }}
                </th>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']; sticky: true"
            ></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="!dataSource?.data.length && !loading" class="no-results centered">
            {{ 'user.subscriptions.no_results' | translate }}
        </div>
    </div>
</ng-template>
