import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../shared/services/user.service';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { User, UserDefaults } from '../shared/model/user.model';
import { UserStatusService } from '../shared/services/user-status.service';
import { Timezone } from '../shared/model/timezone.model';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { Brand, BRANDED_USERS } from '../shared/model/brand.model';
import { BrandsService } from '../shared/services/brands.service';
import { Application } from '../shared/model/application.model';
import { ApplicationsService } from '../shared/services/applications.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from '../shared/model/permission.model';
import { PermissionService } from '../shared/services/permissions.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  canUpdate = false;
  canEditProfile = false;

  userId = '';
  user: User = UserDefaults;
  locales: Locale[];
  timezones: Timezone[];
  applications: Application[];
  brands: Brand[];
  Permission = Permission;
  permissions: any[];
  loggedInUserBrand: string;
  loggedInUser: User;
  showRoles = false;

  loadingUserDetails = false;

  collapseArray: boolean[];

  orgId$: Observable<string>;
  @Input() index = 0;

  erpIconTooltip: MatIconRegistry;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  firstnameLabel: '';
  firstnamePlaceholder: '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userStatusService: UserStatusService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private applicationsService: ApplicationsService,
    private brandsService: BrandsService,
    private contextSelectorService: ContextSelectorService,
    public dialog: MatDialog,
    private permissionsService: PermissionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.erpIconTooltip = iconRegistry.addSvgIcon(
      'general-info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/general-info.svg'),
    );
    const userSub = this.userService.user$.subscribe((user: User) => {
      if (user.userId) {
        this.user = user;
        this.loadingUserDetails = false;
      }
    });

    this.userService.loggedInUser$.subscribe((loggedInUser: User) => {
      if (loggedInUser) {
        this.loggedInUserBrand = loggedInUser.brand;
        this.loggedInUser = loggedInUser;
      }
    });

    const localesSub = this.localesService.locales$.subscribe((locales) => (this.locales = locales));
    const timezonesSub = this.timezonesService.timezones$.subscribe((timezones) => (this.timezones = timezones));
    const applicationsSub = this.applicationsService.applications$.subscribe(
      (applications) => (this.applications = applications),
    );
    const brandsSub = this.brandsService.brands$.subscribe((brands) => (this.brands = brands));

    const permissionSub = this.permissionsService.permissions$.subscribe((permissions) => {
      if (permissions && this.permissionsService.canUpdate()) {
        this.canUpdate = true;
        this.showRoles = true;
      }
      if (permissions && this.permissionsService.canUpdateOwnProfile()) {
        this.canEditProfile = true;
        this.showRoles = true;
      }
    });

    this.orgId$ = this.contextSelectorService.currentContext$.pipe(
      map((orgs: Context[]) => (!!orgs[0] ? orgs[0].id : '')),
    );
    this.subscriptions = this.subscriptions.concat([
      userSub,
      localesSub,
      timezonesSub,
      applicationsSub,
      brandsSub,
      permissionSub,
    ]);
    this.userService.getLoggedInUser();

    this.collapseArray = new Array(4).fill(false);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    const routeSub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.loadingUserDetails = true;
        this.userId = params.id;
        this.userService.selectedUserId$.next(this.userId);
        this.userService.getUser(this.userId);
      }
    });

    this.subscriptions.push(routeSub);
  }

  get doesFullNameContainNames() {
    const fName = (this.user.fullName || '').toLowerCase();
    const firstName = (this.user.first_names[this.user.locale] || '').toLowerCase();
    const lastName = (this.user.last_names[this.user.locale] || '').toLowerCase();
    return fName.includes(firstName) && fName.includes(lastName);
  }

  getStatusForUser() {
    return this.userStatusService.getTranslatedStatus(this.user.status);
  }

  getLocaleForUser() {
    if (this.locales && this.locales.length > 0) {
      return this.locales.find((locale) => this.user.locale.indexOf(locale.localeName) !== -1) || new Locale();
    }
    return new Locale();
  }

  hasMobile() {
    if (this.user.phones && this.user.phones.length > 0) {
      for (const phone of this.user.phones) {
        if (phone.phone_type === 'MOBILE' && !phone.primary) {
          return true;
        }
      }
    }
  }

  hasPhone() {
    if (this.user.phones && this.user.phones.length > 0) {
      for (const phone of this.user.phones) {
        if (phone.phone_type !== 'MOBILE' && phone.primary) {
          return true;
        }
      }
    }
  }

  getTimezoneForUser() {
    if (this.user.timezone && this.timezones.length > 0) {
      return (
        this.timezones.find((timezone) => this.user.timezone.indexOf(timezone.timezoneName) !== -1) || new Timezone()
      );
    }
    return new Timezone();
  }

  getBrandForUser() {
    if (this.user.brand && this.brands.length > 0) {
      const brand = this.brands.find((brand) => this.user.brand.indexOf(brand.name) !== -1);
      return brand.displayLabel || this.user.brand;
    }
    return '';
  }

  getHomeApplicationForUser() {
    if (this.user.home_application && this.applications.length > 0) {
      const application = this.applications.find((app) => this.user.home_application.indexOf(app.name) !== -1);
      return application?.displayLabel || this.user.home_application;
    }
    return '';
  }

  handleEdit() {
    this.router.navigate([`details/${this.userId}/edit`]);
  }

  canEditOwn() {
    if (this.loggedInUser && this.user && this.user.userId) {
      if (this.loggedInUser.userId === this.user.userId) {
        return this.canEditProfile;
      }
    }
    return false;
  }

  isSameBrandUser() {
    if (!this.loggedInUserBrand) {
      return false;
    }
    if (BRANDED_USERS.includes(this.loggedInUserBrand)) {
      return this.loggedInUserBrand === this.user.brand;
    }
    return true;
  }

  enableEditBtn() {
    return (this.canUpdate || this.canEditOwn()) && this.isSameBrandUser();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  expandAll() {
    this.collapseArray = new Array(this.collapseArray.length).fill(true);
  }
  
  collapseAll() {
    this.collapseArray = new Array(this.collapseArray.length).fill(false);
  }

  expansionOpened(i) {
    this.collapseArray[i] = true;
  }

  expansionClosed(i) {
    this.collapseArray[i] = false;
  }

}
