import { DrGroupService } from '../../shared/services/dr-group.service';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { NodeDefinition, TreeNode } from 'ngx-tree-selector';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, combineLatest, merge, of, from } from 'rxjs';
import { BusinessRole, DR_ROLES_TO_SUBSCRIPTIONS } from '../../shared/model/business-role.model';
import { BusinessRolesService } from '../../shared/services/business-roles.service';
import { map } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Context, GlobalNavService, Space } from 'ngx-global-nav';
import { UserService } from '../../shared/services/user.service';
import { User } from 'src/app/shared/model/user.model';
import { CheckableSettings, SelectableSettings } from '@progress/kendo-angular-treeview';
import { iconDefinitions } from 'src/app/shared/model/icons.definitions';
import { ItsService } from '../../shared/services/its.service';
import { SpaceService } from '../../shared/services/space.service';
import { MatCheckbox } from '@angular/material/checkbox';

export interface AdminGroupList {
  letter: string;
  names: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().indexOf(filterValue) === 0);
};
export interface Group extends TreeNode {
  completed: boolean;
  color: ThemePalette;
  subgroups?: Group[];
}
@Component({
  selector: 'app-location-roles',
  templateUrl: './location-roles.component.html',
  styleUrls: ['./location-roles.component.scss'],
})
export class LocationRolesComponent implements OnInit, OnDestroy {
  selectedItemType;
  isRoleDisabled;

  isInit = true;
  loadingHierarchy = true;
  loadingRoles = true;
  applyingRoles = false;
  subscriptions: any[] = [];
  illegalSubs: any[] = [];
  context: Context;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  roleCtrl = new UntypedFormControl();
  filteredRoles$: Observable<BusinessRole[]>;
  allRoles: BusinessRole[];
  allSpaceRoles: BusinessRole[] = [];
  selectedRoles: BusinessRole[] = [];
  @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChildren('roleCheckbox') roleCheckboxes: QueryList<MatCheckbox>;

  multiSelect = true;
  expandAll = false;
  cascadeSelect = false;
  maxSelections = 10;
  disableItems = false;
  loadedALLOrgs = [];

  nodes: TreeNode[] = [];
  orgnodes: TreeNode[];
  marketNodes: TreeNode[];
  allAdminGroups: TreeNode[];
  drGroups;

  chipsFormControl: UntypedFormControl = new UntypedFormControl();

  currentDRType: BehaviorSubject<string> = new BehaviorSubject<string>('SpaceEntity');

  disabledNodeDefinitions: NodeDefinition = { space_type: ['SpaceGroup'] };
  labelIdentifier = 'display_label';
  iconIdentifier = 'spaceType';
  iconDefinitions = iconDefinitions;
  defaultNodes: string[] = [];
  treeSelection: SelectionModel<TreeNode>;
  selectedSpaces: TreeNode[] = [];

  /* Kendo Tree Config */
  searchText = '';
  isHierarchy = false;
  expandedKeys: string[] = [];
  selectedKeys: string[] = [];
  selection: SelectableSettings;
  userDefaultNode: string;
  userDefaultNodeLabel: string;
  showOnlyPrimaryOrg = true;

  ctrlShowAD = new UntypedFormControl(false);
  currentTab = 0;

  voice_notification_user = 'DR_VOICE_NOTIFICATIONS_USER';
  sms_notification_user = 'DR_SMS_NOTIFICATIONS_USER';
  email_notification_user = 'DR_EMAIL_NOTIFICATIONS_USER';
  dr_user = 'DR_GENERAL_USER';
  isVoiceValid = true;
  isSMSValid = true;
  isEmailValid = true;

  @Input() hasAnyAddressField: boolean;
  @Input() hasAnyPhoneField: boolean;
  @Input() hasAnyMobilePhoneField: boolean;
  @Input() hasEmailField: boolean;
  @Input() hasEcrm: boolean;

  @Input() userId: string;
  loggedInUser: User;

  @Output() applyingRolesEvent = new EventEmitter<boolean>();
  @Output() roleError = new EventEmitter<string>();
  @Output() onRoleAdd = new EventEmitter();
  spaces: Space[];

  showOrgSelected = 'optionMainOrg';

  constructor(
    private drGroupService: DrGroupService,
    private rolesService: BusinessRolesService,
    private itsService: ItsService,
    private userService: UserService,
    private globalNavService: GlobalNavService,
    private spaceService: SpaceService,
  ) {
    const rolesSubscription = this.rolesService.businessRoles$.subscribe((roles) => {
      this.allSpaceRoles = roles;
      this.loadingRoles = false;
    });

    const loggedInUserSub = this.userService.loggedInUser$.subscribe((loggedInUser: User) => {
      if (loggedInUser) {
        this.loggedInUser = loggedInUser;
      }
    });

    const userSub = this.userService.user$.subscribe((user: User) => {
      if (user) {
        this.userDefaultNode = user.organization_id;
        this.userDefaultNodeLabel = user.organization_name;
      }
    });

    const userRoleSub = this.globalNavService.userRolesList$.subscribe((roles: any) => {
      if (roles) {
        const consumerAdmin =
          roles.find((role) => role.business_role === 'CONSUMERS_USER_ADMIN') !== undefined ? true : false;
        const userAdmin = roles.find((role) => role.business_role === 'USER_ADMIN') !== undefined ? true : false;
        const showGeneralUser = consumerAdmin && !userAdmin ? true : false;
        const roleSearchChange = merge(of(null), this.roleCtrl.valueChanges);
        this.filteredRoles$ = combineLatest([this.currentDRType, roleSearchChange]).pipe(
          map(([DRType, role]) => this._filterByDRType(DRType, role, showGeneralUser)),
        );
      }
    });

    const spacesSub = this.spaceService.spaces$.subscribe((spaces) => {
      this.spaces = spaces;
    });

    this.subscriptions.push(...[rolesSubscription, loggedInUserSub, userRoleSub, userSub, spacesSub]);
    this.selection = { mode: 'multiple' };
  }

  async onChangeSelectedTab(selectedTab) {
    this.currentTab = selectedTab;
    this.deselectAllLocations();
    this.nodes = [];
    this.selectedRoles = [];
    this.searchText = '';
    this.loadingHierarchy = true;
    this.chipsFormControl.disable();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    switch (selectedTab) {
      case 0:
        this.currentDRType.next('SpaceEntity');
        const nodes0 = await this.loadOrganizationNodes(this.showOnlyPrimaryOrg);
        await this.assignNodes(nodes0, 0);
        break;
      case 1:
        this.currentDRType.next('DREntity');
        const nodes1 = await this.loadMarketNodes();
        this.assignNodes(nodes1, 1);
        break;
      case 2:
        this.currentDRType.next('SpaceEntity');
        const nodes2 = await this.loadAdminGroupsNodes();
        this.assignNodes(nodes2, 2);
        break;
      case 3:
        this.currentDRType.next('DREntity');
        const nodes3 = await this.loadDRGroupsNodes();
        this.assignNodes(nodes3, 3);
        break;
    }
  }

  assignNodes(nodes, nodeTab) {
    if (nodeTab === this.currentTab) {
      this.nodes = nodes;
      this.enableSelection();
    }
  }

  enableSelection() {
    this.chipsFormControl.enable();
    this.loadingHierarchy = false;
  }

  async loadOrganizationNodes(showOnlyPrimaryOrg?: boolean) {
    this.loadingHierarchy = true;
    this.orgnodes = [];
    if (showOnlyPrimaryOrg && this.userDefaultNode) {
      await this.spaceService.getOrgHierarchy(this.userDefaultNode);
      this.spaces = this.spaces.filter((orgs) => orgs.id === this.userDefaultNode);
      this.orgnodes = [...this.spaces];
    } else {
      if (this.loadedALLOrgs.length > 0) {
        this.orgnodes = [...this.loadedALLOrgs];
        await new Promise((resolve) => setTimeout(resolve, 500));
      } else {
        await this.spaceService.getOrgHierarchy(this.userDefaultNode);
        this.orgnodes = this.spaces;
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.loadedALLOrgs = [...this.orgnodes];
      }
    }
    this.searchText = '';
    this.currentSearch = '';
    await this.assignNodes(this.orgnodes, 0);
    return this.orgnodes;
  }

  async loadMarketNodes() {
    if (!this.marketNodes || this.marketNodes.length <= 0) {
      this.marketNodes = await this.spaceService.getSpecificHierarchies([
        'operator',
        'program',
        'product',
        'portfolio',
      ]);
    }
    return this.marketNodes;
  }

  async loadAdminGroupsNodes() {
    if (!this.allAdminGroups || this.allAdminGroups.length <= 0) {
      this.allAdminGroups = await this.spaceService.getSpecificHierarchies(['admingroup']);
    }

    return this.allAdminGroups;
  }

  async loadDRGroupsNodes() {
    if (!this.drGroups || this.drGroups.length <= 0) {
      this.drGroups = await this.drGroupService.getDrGroups();
    }
    return this.drGroups;
  }

  async ngOnInit() {
    this.loadingRoles = true;
    await this.rolesService.getSpaceRoles();
    await this.rolesService.getRoles();
    await this.onChangeSelectedTab(0);
  }

  async applyRoles() {
    const newSpacesWithRoles = this.selectedSpaces.map((space: Group | TreeNode) => {
      return {
        ...{
          id: space.id,
          displayLabel: space.displayLabel,
          spaceType: space.spaceType,
          drType: space.drType,
        },
        activeRoles: [],
        newRoles: [...this.selectedRoles],
      };
    });
    if (this.selectedRoles.length > 0) {
      this.onRoleAdd.emit();
    }

    // Check for legality of new roles
    const subscriptions: any[] = [];
    this.illegalSubs = [];
    newSpacesWithRoles.forEach((space) => {
      const entitySubscriptions = space.newRoles
        .map((role: any) => role.name)
        .filter((role: string) => DR_ROLES_TO_SUBSCRIPTIONS.includes(role));
      if (entitySubscriptions.length > 0 && space.spaceType === 'Site') {
        subscriptions.push({
          entity_id: space.id,
          entity_name: space.displayLabel,
          entity_type: space.spaceType,
          roles: entitySubscriptions,
        });
      }
    });

    if (subscriptions.length > 0 && this.hasEcrm) {
      const siteIds = subscriptions.map((sub) => sub.entity_id);
      const mappedSiteIds = await this.itsService.hasEcrmMapping(siteIds);
      const illegalSet = new Set();
      for (const sub of subscriptions) {
        if (!mappedSiteIds.includes(sub.entity_id)) {
          illegalSet.add(sub.entity_name);
        }
      }
      this.illegalSubs = Array.from(illegalSet);
      if (this.illegalSubs.length > 0) {
        return;
      }
    }
    this._validateFormWithRoles(this.selectedRoles);
    this.rolesService.newSpacesWithRoles$.next(newSpacesWithRoles);
  }

  selectAllLocations() {
    const allSelected = new Set(this.selectedSpaces);
    const allSelectedID = new Set(this.selectedKeys);
    switch (this.currentTab) {
      case 0:
        this.orgnodes.map((item) => {
          allSelected.add(item);
          allSelectedID.add(item.id);
        });
        break;
      case 1:
        this.marketNodes.map((item) => {
          allSelected.add(item);
          allSelectedID.add(item.id);
        });
        break;
      case 2:
        this.allAdminGroups.map((item) => {
          allSelected.add(item);
          allSelectedID.add(item.id);
        });
        break;
      case 3:
        this.drGroups.map((item) => {
          allSelected.add(item);
          allSelectedID.add(item.id);
        });
        break;
    }
    this.selectedSpaces = [...allSelected];
    this.selectedKeys = [...allSelectedID];
    this.disableItems = true;
  }

  deselectAllLocations() {
    this.selectedKeys = [];
    this.selectedSpaces = [];
    this.disableItems = false;
  }

  removeRole(roleName: string): void {
    const role = this.allSpaceRoles.find((role) => role.name === roleName);
    const index = this.selectedRoles.indexOf(role);
    const checkbox = this.roleCheckboxes.find(
      (checkbox) => checkbox._elementRef.nativeElement.innerText.trim() === role.displayLabel,
    );

    if (index >= 0) {
      if (checkbox) {
        checkbox.checked = false;
      }
      this.selectedRoles.splice(index, 1);
    }
    this._validateFormWithRoles(this.selectedRoles);
  }

  selectRole($event, name: string): void {
    //Prevents the parent dropdown from closing.
    $event.stopPropagation();
    const role = this.allSpaceRoles.find((role) => role.name === name);
    if (role) {
      const index = this.selectedRoles.indexOf(role);
      const checkbox = this.roleCheckboxes.find(
        (checkbox) => checkbox._elementRef.nativeElement.innerText.trim() === role.displayLabel,
      );
      //If the role had already been selected, remove it from the selected roles.
      if (index >= 0) {
        //Programatically update the checkboxes to reflect the change.
        if (checkbox && checkbox.checked) {
          setTimeout(() => {
            checkbox.checked = false;
          }, 10);
        }
        this.selectedRoles.splice(index, 1);
      }
      //If the role had not been selected, add it to the selected roles.
      else {
        //Programatically update the checkboxes to reflect the change.
        if (checkbox && !checkbox.checked) {
          setTimeout(() => {
            checkbox.checked = true;
          }, 10);
        }
        this.selectedRoles.push(role);
      }
    }
  }

  private _validateFormWithRoles(role: BusinessRole[]) {
    let isThereAnError = false;
    this.roleError.emit();

    role.map((role) => {
      if (role.name == this.voice_notification_user) {
        if (this.hasAnyPhoneField == false && this.hasAnyMobilePhoneField == false) {
          isThereAnError = true;
          this.roleError.emit(this.voice_notification_user);
          return false;
        }
      }

      if (role.name == this.sms_notification_user) {
        if (this.hasAnyMobilePhoneField == false) {
          this.roleError.emit(this.sms_notification_user);
          isThereAnError = true;
          return false;
        }
      }

      if (this.hasEcrm == true && this.hasAnyAddressField == false) {
        isThereAnError = true;
        this.roleError.emit(this.dr_user);
        return false;
      }
    });

    if (isThereAnError) {
      return false;
    }
    return true;
  }

  public checkRoleDisabled(roleName: string) {
    if (roleName == this.voice_notification_user) {
      return !this.hasAnyPhoneField && !this.hasAnyMobilePhoneField;
    }
    if (roleName == this.sms_notification_user) {
      return !this.hasAnyMobilePhoneField;
    }
    if (roleName == this.email_notification_user) {
      return !this.hasEmailField;
    }
  }

  private _filterByDRType(asigned: string, typedRole: string | null, showGeneralUser: boolean): BusinessRole[] {
    return this.allSpaceRoles.filter((role) => {
      const included = role.assignedAt?.includes(asigned);
      if (!showGeneralUser) {
        if (typedRole && included) {
          const filterValue = typedRole.toLowerCase();
          return role.displayLabel.toLowerCase().indexOf(filterValue) !== -1;
        }
        return included;
      } else if (role.name === 'DR_GENERAL_USER') {
        return included;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  isDisabled = (item: any) => {
    return !this.selectedKeys.includes(item.id) && this.disableItems && !this.nodes.find((node) => node.id == item.id);
  };

  get checkboxSettings(): CheckableSettings {
    return {
      checkChildren: false,
      checkParents: false,
      enabled: true,
      mode: this.selection.mode,
      checkOnClick: false,
    };
  }

  getIconByType(dataItem) {
    const type = dataItem.type || dataItem.drType || dataItem.spaceType;
    if (this.iconDefinitions[type] && this.iconDefinitions[type].name) {
      return this.iconDefinitions[type].name;
    }
    return '';
  }

  handleSelection($event) {
    this.selectedItemType = $event?.item?.dataItem.type;
    const treeNode = $event?.item?.dataItem as TreeNode;
    const index = this.selectedSpaces.findIndex((node) => node.id === treeNode.id);
    if (treeNode.id && index === -1) {
      this.selectedSpaces.push(treeNode);
    } else {
      this.selectedSpaces.splice(index, 1);
    }
  }

  async switchHierarchy(event) {
    this.showOnlyPrimaryOrg = event.value;
    await this.loadOrganizationNodes(this.showOnlyPrimaryOrg);
  }

  loadedOrgIds = [];

  public hasChildren = (node) => {
    if (node.drType === 'Organization') {
      if (this.loadedOrgIds.includes(node.id) && node.children) {
        return node.children.length > 0;
      } else {
        return true;
      }
    } else {
      return node.children && node.children.length > 0;
    }
  };

  public fetchChildren = (item: any) => from(this.getObservableChildrenData(item));

  async getObservableChildrenData(node) {
    return await this.fetchOrgsAsync(node);
  }

  async fetchOrgsAsync(node) {
    if (this.showOnlyPrimaryOrg) {
      return node.children ? node.children : [];
    }
    if (node.children) {
      return node.children;
    }
    if (node.drType === 'Organization' && !this.loadedOrgIds.includes(node.id)) {
      this.loadedOrgIds.push(node.id);
      await this.spaceService.getOrgHierarchy(node.id);
      const expandedOrg = this.spaces.find((org) => org.id == node.id);
      this.nodes.find((storedNode) => storedNode.id === node.id).children = expandedOrg.children;
      node.children = expandedOrg.children;
      this.currentSearch = '';
      return expandedOrg.children;
    } else {
      return node.children ? node.children : [];
    }
  }

  public handleExpand(node) {
    this.expandedKeys = this.expandedKeys.concat(node.index);
  }

  isVisible = () => {
    return true;
  };

  currentSearch = '';
  currentResult = [];

  get filteredNodes() {
    if (this.searchText === '') {
      return this.nodes;
    }
    if (this.currentSearch === this.searchText) {
      return this.currentResult;
    } else {
      this.currentSearch = this.searchText;
      this.currentResult = this.filterByDisplayLabel(this.searchText);
      return this.currentResult;
    }
  }

  filterByDisplayLabel(searchText, nodes = this.nodes) {
    return nodes.reduce((filtered, node) => {
      const newItem = { ...node }; // Create a copy of the item to avoid mutation
      if (newItem.displayLabel.toLowerCase().includes(searchText.toLowerCase())) {
        filtered.push(newItem);
      } else if (newItem.children && newItem.children.length > 0) {
        const filteredChildren = this.filterByDisplayLabel(searchText, newItem.children); // Recursively filter children
        if (filteredChildren.length > 0) {
          newItem.children = filteredChildren;
          filtered.push(newItem);
        }
      }
      return filtered;
    }, []);
  }

  contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf((term || '').toLowerCase()) >= 0;
  }

  deselectAll() {
    this.deselectAllLocations();
    this.roleCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    //Empty the role input field.
    this.roleInput.nativeElement.value = '';
    this.roleCtrl.setValue(null);

    this.selectedRoles = [];
  }
}
