import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DR_ROLES_TO_SUBSCRIPTIONS, SpaceRole, UserRole } from 'src/app/shared/model/business-role.model';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { BusinessRolesService } from 'src/app/shared/services/business-roles.service';
import { ContactSubscriptionService } from 'src/app/shared/services/contact-subscriptions.service';

export interface RoleToDelete {
  role: UserRole;
  entity: SpaceRole;
}

export interface SubmitRolesDialogData {
  totalAddedRoles: number;
  rolesToDelete: RoleToDelete[];
  userId: string;
  contactId: string;
  status: string;
}

@Component({
  selector: 'app-submit-roles-dialog',
  templateUrl: './submit-roles-dialog.component.html',
  styleUrls: ['./submit-roles-dialog.component.scss'],
})
export class SubmitRolesDialogComponent {
  protected dialogState = 'DEFAULT';
  addState = 'DEFAULT';
  delState = 'DEFAULT';
  added = 0;
  deleted = 0;
  errors = 0;
  warning: string;
  totalDeletedRoles: number;
  errorRoles: RoleToDelete[] = [];
  @Output() onRoleUpdate = new EventEmitter<void>();
  @Output() resetRemoved = new EventEmitter<void>();
  @Output() errorRolesEvent = new EventEmitter<RoleToDelete[]>();

  constructor(
    private applicationsService: ApplicationsService,
    private businessRoles: BusinessRolesService,
    private contactSubscriptionService: ContactSubscriptionService,
    public dialogRef: MatDialogRef<SubmitRolesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubmitRolesDialogData,
  ) {
    this.totalDeletedRoles = this.data.rolesToDelete.length;
  }

  onNoClick(): void {
    if (this.dialogState == 'DEFAULT') {
      this.dialogRef.close();
    }
  }

  async handleUpdate() {
    this.errorRoles = [];
    this.errorRolesEvent.emit([]);
    if (this.data.totalAddedRoles > 0) this.onRoleUpdate.emit();
    if (this.totalDeletedRoles > 0) {
      this.setStateUpdating('del');
    }
    for (const role of this.data.rolesToDelete) {
      try {
        if (DR_ROLES_TO_SUBSCRIPTIONS.includes(role.role.businessRole) && role.entity.spaceType === 'Site') {
          const sub = {
            entity_id: role.entity.id,
            entity_type: role.entity.spaceType,
            roles: [role.role.businessRole],
          };
          await this.contactSubscriptionService.updateContactSubscriptions(this.data.userId, [], [sub]);
        }
        await this.businessRoles.deleteRole(this.data.userId, role.entity.id, role.role.businessRole, '', '');
        this.deleted++;
      } catch (e) {
        this.errorRoles.push(role);
        this.errors++;
        console.log(e);
      }
    }
    this.resetRemoved.emit();
    if(this.errors > 0) {
      this.setEndState('del', 'ERROR');
    }
    else {
      this.resetRemoved.emit();
      this.setEndState('del', 'SUCCESS');
    }
    if(this.errors > 0) {
      this.errorRolesEvent.emit(this.errorRoles);
    }
    this.businessRoles.getUserRoles(this.data.userId);
    this.businessRoles.removedAllRoles(this.data.rolesToDelete.map((role) => role.role));
    this.applicationsService.getApplicationsByUser(this.data.userId);
    this.contactSubscriptionService.getContactSubscriptions(this.data.userId);
  }

  closeAfter(ms: number) {
    setTimeout(() => {
      this.dialogRef.close();
    }, ms);
  }

  setStateUpdating(operation: string) {
    if (operation === 'add') {
      this.addState = 'UPDATING';
    } else {
      this.delState = 'UPDATING';
    }

    // Determine conditions
    const isAddUpdating = this.addState === 'UPDATING';
    const hasRolesToAdd = this.data.totalAddedRoles > 0;
    const isDelUpdating = this.delState === 'UPDATING';
    const hasRolesToDelete = this.totalDeletedRoles > 0;

    // Update dialog state based on conditions
    if (
      (isAddUpdating && hasRolesToAdd && isDelUpdating && hasRolesToDelete) ||
      (isAddUpdating && hasRolesToAdd && !hasRolesToDelete) ||
      (!hasRolesToAdd && isDelUpdating && hasRolesToDelete)
    ) {
      this.dialogState = 'UPDATING';
    }
  }

  setEndState(operation: string, state: string) {
    if (operation === 'add') {
      this.addState = state;
    } else {
      this.delState = state;
    }

    // Determine conditions
    const isAddSuccess = this.addState === 'SUCCESS';
    const isAddError = this.addState === 'ERROR';
    const hasRolesToAdd = this.data.totalAddedRoles > 0;
    const isDelSuccess = this.delState === 'SUCCESS';
    const isDelError = this.delState === 'ERROR';
    const hasRolesToDelete = this.totalDeletedRoles > 0;

    // Update dialog state based on conditions
    if (
      (isAddSuccess && hasRolesToAdd && isDelSuccess && hasRolesToDelete) ||
      (isAddSuccess && hasRolesToAdd && !hasRolesToDelete) ||
      (!hasRolesToAdd && isDelSuccess && hasRolesToDelete)
    ) {
      this.dialogState = 'SUCCESS';
      this.closeAfter(2000);
    } else if (
      ((isAddError || isAddSuccess) && hasRolesToAdd && (isDelError || isDelSuccess) && hasRolesToDelete) ||
      ((isAddError || isAddSuccess) && hasRolesToAdd && !hasRolesToDelete) ||
      (!hasRolesToAdd && (isDelError || isDelSuccess) && hasRolesToDelete)
    ) {
      this.dialogState = 'ERROR';
      this.closeAfter(3000);
    }
  }
}
